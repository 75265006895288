import './style.css'
import 'vite/modulepreload-polyfill'

import { initFlowbite } from 'flowbite'
import flatpickr from 'flatpickr'

import Alpine from 'alpinejs'
import anchor from '@alpinejs/anchor'

Alpine.plugin(anchor)
Alpine.start()
window.Alpine = Alpine

function initInputSync() {
    document.querySelectorAll('[data-sync-from]').forEach(($el) => {
        const $from = document.querySelectorAll($el.dataset.syncFrom)
        if (!$from) {
            return
        }

        const setValue = () => {
            let from = $from[0]
            if ($from.length > 1) {
                from = [...$from].find(($el) => $el.checked)
                if (!from) {
                    from = $from[0]
                }
            }
            $el.textContent = from.dataset.syncValue || from.value
        }

        setValue()
        $from.forEach(($el) => {
            $el.addEventListener('change', () => {
                setValue()
            })
        })
    })
}

function initDatepickers() {
    document.querySelectorAll('[data-datepicker]:not([type=hidden])').forEach(($el) => {
        const options = {
            inline: true,
            appendTo: document.querySelector($el.dataset.datepickerContainer || 'body'),
            altInput: true,
            altInputClass: $el.classList.toString() + ' flatpickr-alt-input',
            altFormat: $el.dataset.datepickerFormat || 'Y-m-d',
            enableTime: $el.dataset.datepickerTime === 'true',
            enableSeconds: $el.dataset.datepickerSeconds === 'true',
            time_24hr: $el.dataset.datepicker24hr === 'true',
            onChange: function (selectedDates, dateStr, instance) {
                // dispatch change event on the input bc. it's read-only as we are always using altInput
                const evt = new Event('change')
                instance._input.dispatchEvent(evt)
            },
            prevArrow: `<svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 8 14"
            >
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"
                />
            </svg>`,
            nextArrow: `<svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 8 14"
            >
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                />
            </svg>`,
        }
        for (var name in $el.dataset) {
            const value = $el.dataset[name]
            if (name.startsWith('datepicker')) {
                const optionName = name.replace('datepicker', '').toLowerCase()
                if (optionName) {
                    options[optionName] = value
                }
            }
        }

        const datepicker = flatpickr($el, options)
        const $container = datepicker.calendarContainer
        $container.classList.add('z-10', 'hidden', 'dropdown')
        $container.id = 'dropdown-' + $el.id

        datepicker.altInput.dataset.dropdownToggle = $container.id
        datepicker.altInput.dataset.dropdownPlacement = 'bottom-start'

        datepicker.calendarContainer.addEventListener('change', (evt) => {
            evt.stopPropagation()
        })
    })
}

function delegate(event, params = ['event']) {
    const $ = window.django && window.django.jQuery ? window.django.jQuery : window.jQuery
    const handler = event.startsWith('$')
        ? (...args) => $(document).trigger(event.slice(1), args[0].detail)
        : (...args) => {
              const detail = params.reduce((acc, cur, idx) => {
                  acc[cur] = args[idx]
                  return acc
              }, {})

              detail.event.target.dispatchEvent(
                  new CustomEvent(`$${event}`, {
                      detail,
                      bubbles: true,
                      cancelable: true,
                  })
              )
          }

    event.startsWith('$')
        ? document.addEventListener(event, handler)
        : $(document).on(event, handler)

    return handler
}

window.initInputSync = initInputSync

window.addEventListener('DOMContentLoaded', () => {
    delegate('change')
    initDatepickers()
    initFlowbite()
    initInputSync()
})

window.addEventListener('htmx:afterSettle', () => {
    initDatepickers()
    initFlowbite()
    initInputSync()
})
